/* eslint-disable @nx/enforce-module-boundaries */
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
// libs
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsRouterTracking, AnalyticsScriptsLoaderService } from '@nx-bundesliga/bundesliga-com/framework/analytics';
import { MetaService } from '@nx-bundesliga/bundesliga-com/framework/meta';
import { languageInit, competitionInit, useCompetition, useLanguage } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { Competition, I18NState, Language } from '@nx-bundesliga/models';
import { getWorkingCompetition } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ScriptLoaderService } from '@nx-bundesliga/bundesliga-com/services/script-loader';
import { ConfigService } from '@nx-bundesliga/shared/forked/ngx-config';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { OnetrustScriptsLoaderService } from '../../../web/src/app/framework/cookie/onetrust/onetrust-scripts-loader.service';
import { InstanaScriptsLoaderService } from '../../../web/src/app/framework/monitoring/instana/instana-scripts-loader.service';
import { LoadingService } from '../../../web/src/app/services/loading/loading.service';
import { CookieConsentService } from '@nx-bundesliga/bundesliga-com/services/cookie-consent';

@Component({
	selector: 'bundesliga-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	isBrowser: boolean;

	private routeSubscription: Subscription;
	private compstoreSubscription: Subscription;
	private language: string;

	constructor(
		public el: ElementRef,
		public translate: TranslateService,
		private readonly i18nStore: Store<I18NState>,
		private readonly languageStore: Store<Language>,
		private readonly competitionStore: Store<Competition>,
		private readonly config: ConfigService,
		private cookieConset: CookieConsentService,
		private meta: MetaService,
		// Load Analytics Scripts
		private analyticsScriptsLoaderService: AnalyticsScriptsLoaderService,
		// enable sending tracking information to google Analytics on route changes
		private analyticsRouterTracking: AnalyticsRouterTracking,
		@Inject(PLATFORM_ID) platformId: Object,
		/* website monitoring*/
		private instanaScriptsLoaderService: InstanaScriptsLoaderService,
		private scriptLoader: ScriptLoaderService,
		private route: ActivatedRoute,
		private router: Router,
		private metaService: MetaService,
		private onetrustScriptsLoaderService: OnetrustScriptsLoaderService,
		public loadingService: LoadingService,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document
	) {
		this.competitionStore.dispatch(competitionInit());
		this.isBrowser = isPlatformBrowser(platformId);
	}

	ngOnInit(): void {
		if (this.onetrustScriptsLoaderService.isWebview && !this.onetrustScriptsLoaderService.isAccount && !this.onetrustScriptsLoaderService.urlContainsSuperCupVoting()) {
			this.onetrustScriptsLoaderService.init();
		}
		this.analyticsRouterTracking.init();
		this.i18nStore.dispatch(languageInit(this.config.getSettings('i18n')));
		this.scriptLoader.loadLinkPreconnect([this.config.getSettings('endpoints.defaults.base'), 'https://assets.bundesliga.com']);
		this.instanaScriptsLoaderService.init();

		this.setDocumentDir('ltr'); // Set default direction
		this.meta.setTag('viewport', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');

		this.routeSubscription = this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((nav: NavigationEnd) => {
					const url = nav.urlAfterRedirects.split('/').filter((val) => val !== '');
					const urlParams = {
						language: this.config.getSettings('i18n.defaultLanguage.code'),
						competition: 'bundesliga'
					};
					if (url.length >= 1) {
						urlParams.language = url[0];
						if (url.length >= 2 && ['bundesliga', '2bundesliga'].includes(url[1])) {
							urlParams.competition = url[1];
						}
					}

					return urlParams;
				}),
				distinctUntilChanged((p, c) => p.language === c.language && p.competition === c.competition)
			)
			.subscribe((params: { language: string; competition: string }) => {
				this.i18nStore.dispatch(useLanguage({ language: params.language }));
				this.competitionStore.dispatch(useCompetition({ competition: params.competition }));

				this.language = params.language;
				this.setDocumentDir(this.language === 'ar' ? 'rtl' : 'ltr');
			});

		this.compstoreSubscription = this.competitionStore.pipe(select(getWorkingCompetition)).subscribe((competition: Competition) => {
			// the markup twitter:xyz needs to stay for the moment due x did not change it https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/markup
			this.metaService.setTag('twitter:image', this.config.getSettings('system.applicationUrl') + '/assets/share/fallback-' + competition.name + '-2021-2022.jpg');
			this.metaService.setTag('og:image', this.config.getSettings('system.applicationUrl') + '/assets/share/fallback-' + competition.name + '-2021-2022.jpg');
		});
	}

	private setDocumentDir(dir: 'ltr' | 'rtl') {
		this.renderer.setAttribute(this.document.documentElement, 'dir', dir);
	}

	ngOnDestroy() {
		this.compstoreSubscription.unsubscribe();
		this.routeSubscription.unsubscribe();
	}
}
